import { graphql } from "gatsby"
import React from "react"

import ProductView from "../components/product/product-view/product-view"
import { ProductsDatabase } from "../components/product/products-database"
import useProductParameterGroups from "../hooks/useProductParameterGroups"
import useProducts from "../hooks/useProducts"
import { useTranslation } from "../i18n/translate"

const ProductsByBrand = ({ pageContext: { brand }, data, location, path }) => {
  const productParamGroups = useProductParameterGroups()
  const productParameterGroupsForFilter = productParamGroups.concat([
    {
      name: { key: "MARKETS-MARKET" },
      info: { key: "PRODUCTS-PRODUCT_FILTERS-MARKET-INFO" },
      strapiId: -2,
      prefix: null,
      suffix: null,
      type: "ENUM",
      order: 110,
    },
  ])

  const products = useProducts(true).filter((product) => {
    return product.hasBrand(brand)
  })

  const db = new ProductsDatabase(products, productParameterGroupsForFilter)

  const { t } = useTranslation()

  return (
    <>
      <ProductView
        filterKey={"brand_" + brand}
        db={db}
        descriptionTranslatedString={t("PRODUCTS-META_DESCRIPTION")}
        titleTranslatedString={t(data.strapiBrand?.name?.key)}
        productParamGroups={productParamGroups}
        location={location}
        path={path}
      />
    </>
  )
}

export default ProductsByBrand

export const query = graphql`
  query ($brand: String) {
    strapiBrand(url: { eq: $brand }) {
      id
      name {
        key
      }
    }
  }
`
